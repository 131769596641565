require('../css/app.css');

const $ = require('jquery');
global.$ = global.jQuery = $;

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

global.Routing = Routing;

Routing.setRoutingData(routes);

window.Popper = require('popper.js').default;

require('bootstrap');
require('bootstrap/dist/css/bootstrap.min.css');

require('@fortawesome/fontawesome-free');
require('@fortawesome/fontawesome-free/css/all.min.css');

window.toastr = require('toastr');
require('toastr/build/toastr.min.css');

require('metismenu');
require('metismenu/dist/metisMenu.min.css');

require('jquery-slimscroll');

require('select2/dist/js/select2.full.min');
require('select2/dist/css/select2.min.css');

require('iCheck/icheck.min');
require('iCheck/skins/square/grey.css');

require('webticker/jquery.webticker.min.js');

window.swal = require('sweetalert2');

$(document).ready(function () {

  let flashes = $('body').data('flashes');
  if (flashes !== 'undefined' && flashes !== '') {
    $.each(flashes, function (key, val) {
      loadToastrMsg(key, val);
    });
  }

  if ($().DataTable) {
    initTable();
    setClickableRows('.dataTables');

    let colSelector = $('.colSelector');

    colSelector.each(function () {
      if (!$(this).is(':checked')) {
        showColumn($(this).val());
      }
    });

    colSelector.on('click', function () {
      showColumn($(this).val());
    });
  }

  if ($().select2) {
    $('.select2:not([readonly])').select2({
      templateResult: function (data, container) {
        if (data.element) {
          $(container).addClass($(data.element).attr('class'));
        }
        return data.text;
      },
    });

    $('.select2tags:not([readonly])').select2({
      tags: true,
      selectOnClose: false,
      allowClear: true,
      createSearchChoice: function (term, data) {
        if ($(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0) {
          return {id: '', text: term};
        }
      },
    });

    $('.select2modal:not([readonly])').select2({
      dropdownParent: $('#taskModal'),
      width: '100%',
    });
  }

  $('#registration_form_company_name').on('blur', function () {
    let path = $(this).closest('form').data('pathsubdomain');
    getSubdomain($(this).val(), path);
  });

  $('.i-checks').iCheck({
    checkboxClass: 'icheckbox_square-green',
    radioClass: 'iradio_square-green',
  });

  loadWantedTicker();
  loadNavigationCount();

  // Certain forms want to show a prompt to users
  // that fields have changed, and remind them to save.
  // And a hack to delay tracking changes for 5 seconds!
  setTimeout(function(){
    $('form.track-changes').on('keyup change paste', 'input, select, textarea', function(){
      $('.alert-track-changes').show();
    });
  }, 5000);

});

function initTable() {

  let table = $('.dataTables').DataTable({pageLength: 25, retrieve: true });

  if ($().dataTable.FixedHeader) {
    new $.fn.dataTable.FixedHeader(table);
  }
  return table;
}

function showColumn(colNo) {

  let table = initTable();
  let column = table.column(colNo);

  column.visible(!column.visible());
}

function setClickableRows(table) {
  $(table + ' tbody').on('click', 'tr', function () {
    if (typeof $(this).data('link') !== 'undefined') {
      location.href = $(this).data('link');
    }
  });
}

export function loadToastrMsg(type, content) {
  toastr.options = {
    closeButton: true,
    progressBar: true,
    showMethod: 'slideDown',
    timeOut: 5000,
    extendedTimeOut: 5000
  };

  if (type === 'success') {
    toastr.success(content, 'Success');
  }

  if (type === 'info') {
    toastr.info(content, 'Info');
  }

  if (type === 'error' || type === 'warning' || type === 'danger') {
    toastr.error(content, 'Problem Occurred');
  }
}

function getSubdomain(companyName, path) {
  $.ajax({
    url: path,
    data: {companyName: companyName},
    type: 'POST',
    dataType: 'json',
    success: function (response) {
      if (response && response.success === true) {

        $('#registration_form_subdomain').val(response.subdomain);

      } else {
        alert('Error occurred. Please try again.');
      }
    }
  });
}

export function deletionConfirmationAjax(path, callback, text, textconfirm, data) {
  swal.fire({
    title: 'Are you sure?',
    text: text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33333',
    confirmButtonText: 'Yes',
    focusConfirm: false,
  }).then((result) => {
    if (result.value) {

      $.ajax({
        url: path,
        data: data,
        type: 'POST',
        dataType: 'json',
        success: function (response) {

          if (response.success === true || response.success === "true") {
            callback();
          } else {
            alert('Error occurred. Please try again. Ref #0002');
          }
          // Delete confirmation message
          swal.fire('Success', textconfirm, 'success');
        },
        error: function (response) {
          alert('Error occurred. Please try again. Ref #0001');
        }
      });


    }
  });
}

export function deletionConfirmation(path, pathreload, text, textconfirm) {
  swal.fire({
    title: 'Are you sure?',
    text: text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ed5565',
    confirmButtonText: 'Yes, delete',
    focusConfirm: false,
  }).then((result) => {
    if (result.value) {
      postToPath(path);
      swal.fire('Deleted', textconfirm, 'success').then(function () {
        location.href = pathreload;
      });
    }
  });
}

export function generalConfirmation(path, pathreload, properties) {
  swal.fire({
    title: 'Are you sure?',
    text: properties.text,
    type: properties.type,
    showCancelButton: true,
    confirmButtonColor: properties.btnColor,
    confirmButtonText: properties.btnText,
    focusConfirm: false,
  }).then((result) => {
    if (result.value) {
      postToPath(path);
      swal.fire(properties.swalFeedbackTitle, properties.swalFeedbackText, 'success').then(function () {
        location.href = pathreload;
      });
    }
  });
}

export function postToPath(path) {
  $.post(path, function (response) {
    return response;
  });
}

export function ajaxSwalAction(path, callback, data) {

  if (data.params === undefined) {
    return;
  }

  let options = {
    title: data.params.title,
    text: data.params.text,
    type: data.params.type ? data.params.type : 'question',
    showCancelButton: true,
    confirmButtonText: data.params.btnText ? data.params.btnText : 'Save',
    confirmButtonColor: data.params.btnColor ? data.params.btnColor : '#1c84c6',
    focusConfirm: false,
  };

  if (data.params.options !== undefined) {
    options.input = 'select';
    options.inputOptions = data.params.options;
    options.inputPlaceholder = data.params.placeholder;
    options.inputValue = data.params.selected;
  }

  if (data.params.input !== undefined) {
    options.input = data.params.input;
    options.inputValue = data.params.inputValue ? data.params.inputValue : '';
  }

  swal.fire(options).then((response) => {
    if (response.value) {
      data.value = response.value;
      $.ajax({
        url: path,
        data: data,
        type: 'POST',
        dataType: 'json',
        success: function (response) {
          if (response.success === true) {
            let f = new Function(callback);
            f();

            swal.fire('', response.feedback, 'success');
          } else {
            alert('Error occurred. Please try again.');
          }
        }
      });
    }
  });
}

function loadWantedTicker() {

  $.ajax({
    url: Routing.generate('api_wanted_ticker'),
    type: 'POST',
    dataType: 'json',
    success: function (response) {
      if (response.success === true) {

        $('#webTicker').html(response.data);
        $('#webTicker').webTicker({
          duplicate: true,
          rssfrequency: 0,
          startEmpty: false,
          hoverpause: false,
          transition: 'ease'
        });

      } else {
        alert('Error occurred. Please try again.');
      }
    }
  });
}

function loadNavigationCount() {
  let nav = $('nav.navbar-default');

  $.ajax({
    url: Routing.generate('api_navigation_count'),
    type: 'POST',
    dataType: 'json',
    success: function (response) {
      if (response.success === true) {
        nav.find('.count-my').html('(' + response.my + ')');
        nav.find('.count-all').html('(' + response.all + ')');
        nav.find('.count-brokerage').html('(' + response.brokerage + ')');
        nav.find('.count-used').html('(' + response.used + ')');
        nav.find('.count-new').html('(' + response.new + ')');
        nav.find('.count-draft').html('(' + response.draft + ')');
      } else {
        alert('Error occurred. Please try again.');
      }
    }
  });
}

export function startSpinner(selector) {
  $(selector).find('.ibox-content').addClass('sk-loading');
}

export function stopSpinner(selector) {
  $(selector).find('.ibox-content').removeClass('sk-loading');
}

export function ajaxLog(message,extra) {
  $.ajax({
    url: Routing.generate('api_system_log'),
    data: {message:message,extra:extra},
    type: 'POST',
    dataType: 'json',
    success: function (response) {

    }
  });
}

//////////////////////////////
// Disable some inputs and buttons
//////////////////////////////
$(document).ready(function () {
  let _main = $(document).find('.main-content');
  let readonly = $(document).find('input[name="PAGE_READONLY"]').val();
  if(readonly == 1){
    _main.find('.btn-primary').remove();
    _main.find('.btn-danger').remove();
    _main.find('input').prop('disabled',true);
    _main.find('select').prop('disabled',true);
    _main.find('select').select2("enable", false);
  }
});
